import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AdminAuthState, AdminSignout } from '../store/auth.state';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private store: Store, private router: Router) {}

  canActivate() {
    if (!this.store.selectSnapshot(AdminAuthState.isAuthenticated)) {
      this.store.dispatch(new AdminSignout());
      this.router.navigateByUrl('/login');
      return false;
    }
    return true;
  }
}
