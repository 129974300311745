import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { AdminSignin } from '../../../shared/store/auth.state';

@Component({
  selector: 'bo-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
  form = this.fb.group({
    login: ['', Validators.required],
    password: ['', Validators.required],
  });
  isLoading = false;
  error: string = null;

  constructor(private fb: FormBuilder, private store: Store, private cd: ChangeDetectorRef, private router: Router) {}

  signin(): void {
    if (!this.isLoading && this.form.valid) {
      this.isLoading = true;
      const login = this.form.get('login').value;
      const password = this.form.get('password').value;

      this.store
        .dispatch(new AdminSignin(login, password))
        .pipe(
          take(1),
          catchError(err => {
            this.isLoading = false;
            if (err.status === 401) {
              this.error = 'Identifiants incorrects';
            } else {
              this.error = 'Une erreur inconnue est survenue';
            }
            this.cd.detectChanges();
            return throwError(err);
          }),
          tap(() => this.router.navigateByUrl('')),
        )
        .subscribe();
    }
  }
}
