import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { forkJoin, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AdminSignout } from '../../shared/store/auth.state';

@Component({
  selector: 'bo-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  destroy$ = new Subject();

  constructor(private router: Router, private store: Store) {}

  logout(): void {
    forkJoin([this.store.dispatch(new AdminSignout())])
      .pipe(
        tap(() => {
          this.router.navigateByUrl('/login');
        }),
      )
      .subscribe();
  }
}
