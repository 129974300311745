import { Injectable } from '@angular/core';
import { User } from '@idea/models';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import jwt_decode from 'jwt-decode';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

export class AdminAuthStateModel {
  token: string;
  refreshToken: string;
  user: Partial<User>;
}

export class AdminSignin {
  static readonly type = '[Authbo] Signin';
  constructor(public email: string, public password: string) {}
}

export class AdminRefreshToken {
  static readonly type = '[Authbo] RefreshToken';
}

export class AdminSignout {
  static readonly type = '[Authbo] Signout';
}

@State<AdminAuthStateModel>({
  name: 'authbo',
  defaults: {
    token: null,
    refreshToken: null,
    user: null,
  },
})
@Injectable()
export class AdminAuthState {
  @Selector()
  static isAuthenticated(state: AdminAuthStateModel): boolean {
    return !!state.token && Date.now() < (jwt_decode(state.token) as { exp: number }).exp * 1000;
  }

  @Selector()
  static token(state: AdminAuthStateModel): string {
    return state.token;
  }

  @Selector()
  static refreshToken(state: AdminAuthStateModel): string {
    return state.refreshToken;
  }

  constructor(private authService: AuthService) {}

  @Action(AdminSignin)
  login(
    { patchState }: StateContext<AdminAuthStateModel>,
    { email, password }: AdminSignin,
  ): Observable<{ access_token: string; refresh_token: string }> {
    return this.authService.signIn$(email, password).pipe(
      tap(res =>
        patchState({
          token: res.access_token,
          refreshToken: res.refresh_token,
        }),
      ),
    );
  }

  @Action(AdminRefreshToken)
  refreshToken({
    getState,
    patchState,
  }: StateContext<AdminAuthStateModel>): Observable<{ access_token: string; refresh_token: string }> {
    const refreshToken = getState().refreshToken;
    return this.authService.refreshToken$(refreshToken).pipe(
      tap(res =>
        patchState({
          token: res.access_token,
          refreshToken: res.refresh_token,
        }),
      ),
    );
  }

  @Action(AdminSignout)
  logout({ setState }: StateContext<AdminAuthStateModel>): void {
    setState({
      token: null,
      refreshToken: null,
      user: null,
    });
  }
}
