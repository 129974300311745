<div class="login-container">
  <h1>Connexion Back-Office</h1>

  <form [formGroup]="form" (ngSubmit)="signin()">
    <mat-form-field>
      <mat-label>Email</mat-label>
      <input type="email" matInput formControlName="login" placeholder="Email" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Mot de passe</mat-label>
      <input type="password" matInput formControlName="password" placeholder="Mot de passe" />
    </mat-form-field>

    <div class="text-center">
      <button mat-stroked-button type="submit" color="primary" [disabled]="isLoading" [class.is-loading]="isLoading">
        Se connecter
      </button>
    </div>
    <p *ngIf="!!error">{{ error }}</p>
  </form>
</div>
