<mat-toolbar>
  <a routerLink="/">
    <img src="assets/img/IDEA_logo.svg" alt="IDEA" height="50px" width="auto" />
  </a>

  <div class="zone-menu">
    <ul class="menu">
      <li>
        <a routerLink="crons" routerLinkActive="active">Tâches CRON</a>
      </li>
      <li>
        <a routerLink="education" routerLinkActive="active">Parcours pédagogiques</a>
      </li>
      <li>
        <a routerLink="modalities" routerLinkActive="active">Modalités</a>
      </li>
    </ul>
    <button [matMenuTriggerFor]="menu" [class.active]="true" aria-label="Profil" class="btn-profile">
      <span class="icon-profile"></span>
    </button>
  </div>
</mat-toolbar>

<mat-menu #menu="matMenu" class="menu-profile">
  <button mat-menu-item (click)="logout.emit()">Se déconnecter</button>
</mat-menu>
