import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthModule } from '../features/auth/auth.module';
import { LoginComponent } from '../features/auth/login/login.component';
import { AuthGuard } from '../shared/guards/auth.guard';
import { LayoutComponent } from './layout/layout.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'education',
        pathMatch: 'full',
      },
      {
        path: 'education',
        loadChildren: () => import('../features/education/education.module').then(m => m.EducationModule),
      },
      {
        path: 'modalities',
        loadChildren: () => import('../features/modality/modality.module').then(m => m.ModalityModule),
      },
      {
        path: 'crons',
        loadChildren: () => import('../features/crons/crons.module').then(m => m.CronsModule),
      },
    ],
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  { path: '**', redirectTo: 'education' },
];

@NgModule({
  imports: [
    AuthModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class CoreRoutingModule {}
