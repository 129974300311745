import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { AdminAuthState, AdminRefreshToken, AdminSignout } from '../store/auth.state';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private refreshingInProgress: boolean;
  private accessTokenSubject: BehaviorSubject<string> = new BehaviorSubject(null);

  constructor(private router: Router, private store: Store) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = this.store.selectSnapshot(AdminAuthState.token);
    return next.handle(this.addAuthorizationHeader(req, accessToken)).pipe(
      catchError(err => {
        // Maintenance
        if (err instanceof HttpErrorResponse && err.status === 503) {
          return this.goToMaintenance(err);
        }
        // in case of 401 http error
        if (err instanceof HttpErrorResponse && err.status === 401) {
          if (err.error.message === 'WRONG_CREDENTIALS') {
            //Do nothing
            return throwError(err);
          } else {
            // get refresh tokens
            // if there are tokens then send refresh token request
            const refreshToken = this.store.selectSnapshot(AdminAuthState.refreshToken);
            if (refreshToken && accessToken) {
              return this.refreshToken(req, next);
            } // otherwise logout and redirect to login page
            return this.logoutAndRedirect(err);
          }
        } // in case of 403 http error (refresh token failed)
        if (err instanceof HttpErrorResponse && err.status === 403) {
          if (err.error.message === 'REFRESH_TOKEN_EXPIRED') {
            // logout and redirect to login page
            return this.logoutAndRedirect(err);
          }
        }

        // if error has status neither 401 nor 403 then just return this error
        return throwError(err);
      }),
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private addAuthorizationHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
    // On ajoute le header d'authentification sur les requêtes à destination de l'api
    if (token) {
      return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
    }
    return request;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private logoutAndRedirect(err): Observable<HttpEvent<any>> {
    this.store.dispatch(new AdminSignout());
    this.router.navigateByUrl('/login');
    return throwError(err);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private goToMaintenance(err): Observable<HttpEvent<any>> {
    this.router.navigateByUrl('/maintenance');
    return throwError(err);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.refreshingInProgress) {
      this.refreshingInProgress = true;
      this.accessTokenSubject.next(null);
      return this.store.dispatch(new AdminRefreshToken()).pipe(
        map(() => {
          return this.store.selectSnapshot(AdminAuthState.token);
        }),
        switchMap(access_token => {
          this.refreshingInProgress = false;
          this.accessTokenSubject.next(access_token);
          // repeat failed request with new token
          return next.handle(this.addAuthorizationHeader(request, access_token));
        }),
      );
    } else {
      // wait while getting new token
      return this.accessTokenSubject.pipe(
        filter(token => token !== null),
        take(1),
        switchMap(token => {
          // repeat failed request with new token
          return next.handle(this.addAuthorizationHeader(request, token));
        }),
      );
    }
  }
}
